<template>
    <div class="card text-center ml-4 mr-4 mt-4 bg-primary">
        Closing Session...
    </div>
</template>
<script>
import { mapActions, mapGetters } from 'vuex';
import axios from 'axios';
import urls from '@/data/urls';

export default {
    name     : 'LogoutPage',
    computed : {
        ...mapGetters(['currentUser'])
    },
    beforeMount () {
        this.logout();
    },
    methods : {
        ...mapActions(['signOut']),
        async logout (user) {
            const response = await axios.get(urls.auth.logout);
            if (response.error === false) {
                this.signOut();
                this.$router.push('/login/').catch(() => {});
            } else {
                this.signOut();
                this.$router.push('/login/').catch(() => {});
            }
        }
    }
};
</script>

<style scoped>

</style>
